import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { MdCloudDownload } from 'react-icons/md';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import CallToAction from '../components/CallToAction';

const EquipmentPage = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicEquipment.data.meta_title}
      meta_description={data.prismicEquipment.data.meta_description}
    />
    <Hero
      title={data.prismicEquipment.data.equipment_title.text}
      fluid={data.prismicEquipment.data.card_image.localFile.childImageSharp.fluid}
      overlay="bg-black opacity-50"
    />
    <section className="bg-grey-darker text-grey-light">
      <div className="container">
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-4/5 lg:w-1/2 mb-10">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicEquipment.data.equipment_description.html,
              }}
            />
          </div>
        </div>

        {data.prismicEquipment.uid === 'desiccant-dryair-equipment' ? (
          <div className="overflow-auto">
            <table className="table striped">
              <thead className="bg-black">
                <tr className="text-center">
                  <th className="text-center">Model</th>
                  <th className="text-center">Inlet Flow</th>
                  <th className="text-center">Flow Outlet</th>
                  <th className="text-center">Working Pressure</th>
                  <th className="text-center">Dimensions (HxWxD)</th>
                  <th className="text-center">Weight</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>RDHHP715</td>
                  <td className="text-right">1300cfm</td>
                  <td className="text-right">1180cfm</td>
                  <td className="text-center">350psi</td>
                  <td className="text-center">96" x 78" x 96"</td>
                  <td className="text-right">4000lbs</td>
                </tr>
                <tr>
                  <td>RDHHP1550G</td>
                  <td className="text-right">2800cfm</td>
                  <td className="text-right">2550cfm</td>
                  <td className="text-center">350psi</td>
                  <td className="text-center">96" x 78" x 96"</td>
                  <td className="text-right">6200lbs</td>
                </tr>
                <tr>
                  <td>RDHHP5200G</td>
                  <td className="text-right">9300cfm</td>
                  <td className="text-right">8400cfm</td>
                  <td className="text-center">350psi</td>
                  <td className="text-center">81" x 91" x 153"</td>
                  <td className="text-right">19000lbs</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}

        {data.prismicEquipment.data.product_info.length > 0 && (
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-4/5 lg:w-1/2">
              <table className="table striped">
                <thead className="bg-black">
                  <tr>
                    <th className="text-center">Size</th>
                    <th className="text-center">ID</th>
                    <th className="text-center">View/Download</th>
                  </tr>
                </thead>
                <tbody>
                  {data.prismicEquipment.data.product_info.map(product => (
                    <tr key={product.item_id}>
                      <td className="text-center">{product.size}</td>
                      <td className="text-center">{product.item_id}</td>
                      <td className="text-center">
                        {product.item_download.url ? (
                          <a href={product.item_download.url}>
                            <MdCloudDownload />
                          </a>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </section>

    <div className="flex flex-wrap">
      {data.prismicEquipment.data.gallery.map(({ slide }) => (
        <div className="w-full md:w-1/2 relative" key={slide.localFile.id}>
          <Img
            fluid={slide.localFile.childImageSharp.fluid}
            alt={slide.alt ? slide.alt : data.prismicEquipment.data.equipment_title.text}
          />
          {slide.alt ? <p className="bg-black text-white mb-0 py-2 px-4 absolute pin-b">{slide.alt}</p> : null}
        </div>
      ))}
    </div>

    <CallToAction />
  </Layout>
);

export default EquipmentPage;

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicEquipment(uid: { eq: $uid }) {
      uid
      data {
        equipment_title {
          text
        }
        equipment_description {
          html
        }
        card_image {
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        gallery {
          slide {
            alt
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 533, quality: 80, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        product_info {
          item_id
          size
          item_download {
            url
            size
          }
        }
        meta_title
        meta_description
      }
    }
  }
`;
